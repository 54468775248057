var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    {
      ref: "headerId",
      staticClass: "header",
      attrs: {
        slot: "header",
        id: _vm.content && _vm.content.id,
        model: _vm.content
      },
      slot: "header"
    },
    [_vm._v("\n  " + _vm._s(_vm.content && _vm.content.title) + "\n")]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }