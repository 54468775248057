// 混合中全局引入的组件
import modalHeader from '../../../components/common/modalHeader'
import loadBtn from '../../../components/common/loadBtn'
import remove from '../../../components/common/remove'
import uploadFile from '../../../components/common/uploadFile'
import operate from '../../../components/common/operate'
import layoutTree from '../../../components/common/layoutTree'
import leftTree from '../../../components/common/leftTree'
import selectUser from '../../../components/common/selectUser'
import dateGroup from '../../../components/common/dateGroup'
import searchSelect from '../../../components/common/searchSelect'
import selectOption from '../../../components/common/selectOption'
import xlsx from '../../../components/common/vue-xlsx-table'
import derive from '../../../components/common/derive'
import dictionarySelect from '../../../components/common/dictionarySelect'

export default {
  modalHeader,
  loadBtn,
  remove,
  uploadFile,
  operate,
  layoutTree,
  leftTree,
  selectUser,
  dateGroup,
  searchSelect,
  selectOption,
  xlsx,
  derive,
  dictionarySelect
}
