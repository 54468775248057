var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    {
      class: {
        onlyUploadShow: _vm.uploadShow,
        noFile: _vm.uploadShow && !_vm.fileList.length
      }
    },
    [
      _c(
        "el-upload",
        {
          ref: "upload",
          class: {
            uploadShow: _vm.uploadShow,
            "picture-card": _vm.listType == "picture-card"
          },
          attrs: {
            multiple: _vm.multiple,
            "with-credentials": "",
            "before-upload": _vm.beforeUpload,
            "on-progress": _vm.onProgress,
            "on-success": _vm.onSuccess,
            "on-error": _vm.onError,
            "on-preview": _vm.onPreview,
            "on-remove": _vm.onRemove,
            "on-format-error": _vm.onFormatError,
            "on-exceeded-size": _vm.onExceededSize,
            "file-list": _vm.fileList,
            drag: _vm.isDrag,
            headers: _vm.headers,
            "list-type": _vm.listType,
            action: _vm.upUrl,
            data: _vm.upData,
            "auto-upload": _vm.autoUpload,
            limit: _vm.length
          }
        },
        [
          _vm.listType == "text" && _vm.listLength < _vm.data.length
            ? _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.uploadShow,
                      expression: "!uploadShow"
                    }
                  ]
                },
                [
                  _c(
                    "el-button",
                    {
                      staticClass: "blueBtn"
                    },
                    [_vm._v("\n          点击上传\n        ")]
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.listType == "picture" && _vm.listLength < _vm.data.length
            ? _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.uploadShow,
                      expression: "!uploadShow"
                    }
                  ],
                  staticStyle: {
                    padding: "20px 0"
                  }
                },
                [
                  _c("Icon", {
                    staticStyle: {
                      color: "#3399ff"
                    },
                    attrs: {
                      type: "ios-cloud-upload",
                      size: "52"
                    }
                  }),
                  _c("p", [_vm._v("点击或将文件拖拽到这里上传")])
                ],
                1
              )
            : _vm._e(),
          _vm.listType == "picture-card"
            ? _c("i", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.uploadShow,
                    expression: "!uploadShow"
                  }
                ],
                staticClass: "el-icon-plus"
              })
            : _vm._e(),
          _vm.data.message
            ? _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.uploadShow,
                      expression: "!uploadShow"
                    }
                  ],
                  staticClass: "el-upload__tip",
                  attrs: {
                    slot: "tip"
                  },
                  slot: "tip"
                },
                [_vm._v("\n        " + _vm._s(_vm.data.message) + "\n      ")]
              )
            : _vm._e()
        ]
      ),
      _vm.uploadShow && !_vm.fileList.length
        ? _c(
            "div",
            {
              staticClass: "noFileTips"
            },
            [_vm._v("\n      暂无附件\n    ")]
          )
        : _vm._e(),
      _vm.listType == "picture-card"
        ? _c(
            "el-dialog",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.uploadShow,
                  expression: "!uploadShow"
                }
              ],
              attrs: {
                size: "tiny"
              },
              model: {
                value: _vm.dialogVisible,
                callback: function callback($$v) {
                  _vm.dialogVisible = $$v
                },
                expression: "dialogVisible"
              }
            },
            [
              _c("img", {
                attrs: {
                  width: "100%",
                  src: _vm.dialogImageUrl,
                  alt: ""
                }
              })
            ]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }