var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    [
      _c(
        "layout-tree",
        {
          staticStyle: {
            height: "550px"
          }
        },
        [
          _c("left-tree", {
            attrs: {
              slot: "left",
              "tree-options": _vm.treeDefaults,
              "from-where-tree-type": _vm.fromWhereTree
            },
            on: {
              "tree-click": _vm.treeClick
            },
            slot: "left"
          }),
          _c(
            "div",
            {
              ref: "content",
              staticClass: "modal",
              attrs: {
                slot: "right",
                id: "content"
              },
              slot: "right"
            },
            [
              _c("br"),
              _c(
                "div",
                {
                  staticStyle: {
                    margin: "0 auto",
                    width: "650px"
                  }
                },
                [
                  _c("ambufTransfer", {
                    staticClass: "my-select-user",
                    attrs: {
                      data: _vm.leftListData,
                      "target-keys": _vm.rightTargetKeys,
                      "list-style": _vm.listStyle,
                      "render-format": _vm.render3,
                      operations: ["向左移动", "向右移动"],
                      "not-found-text": "无信息,请选择左侧菜单!",
                      filterable: ""
                    },
                    on: {
                      "on-change": _vm.handleChange3
                    }
                  })
                ],
                1
              ),
              _c("br"),
              _c("div", {
                staticClass: "ivu-tabs-bar"
              }),
              _c(
                "div",
                {
                  staticStyle: {
                    "text-align": "center"
                  }
                },
                [
                  _c("load-btn", {
                    attrs: {
                      "btn-data": _vm.loadBtn
                    },
                    on: {
                      listenSubEvent: _vm.listenSubEvent
                    }
                  }),
                  _c(
                    "el-button",
                    {
                      staticClass: "cancelBtn",
                      on: {
                        click: _vm.cancel
                      }
                    },
                    [_vm._v("\n          取消\n        ")]
                  )
                ],
                1
              )
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }