var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    _vm._l(_vm.optionData, function(item) {
      return _c("el-option", {
        key: item.id,
        attrs: {
          label: item[_vm.name || "name"],
          value: item[_vm.id || "id"]
        }
      })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }