var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    [
      _vm.operailityData.types === "BKS" && _vm.operailityData.parentId === -101
        ? _c("selectSchool", {
            attrs: {
              type: _vm.type,
              "operaility-data": _vm.operailityData
            },
            on: {
              edit: function edit($event) {
                return _vm.addCallBack(_vm.type)
              },
              cancel: _vm.cancel
            }
          })
        : _c(
            "div",
            [
              _c(
                "el-form",
                {
                  ref: "formValidate",
                  staticClass: "demo-form-inline",
                  attrs: {
                    model: _vm.formValidate,
                    rules: _vm.rules,
                    "label-width": "110px"
                  }
                },
                [
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        {
                          attrs: {
                            span: 11,
                            offset: 1
                          }
                        },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "部门名称：",
                                prop: "name"
                              }
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "请输入"
                                },
                                model: {
                                  value: _vm.formValidate.name,
                                  callback: function callback($$v) {
                                    _vm.$set(_vm.formValidate, "name", $$v)
                                  },
                                  expression: "formValidate.name"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        {
                          attrs: {
                            span: 11
                          }
                        },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "管理员：",
                                prop: "parentId"
                              }
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  readonly: "",
                                  placeholder: "请输入"
                                },
                                on: {
                                  focus: _vm.addUser
                                },
                                model: {
                                  value: _vm.userNames,
                                  callback: function callback($$v) {
                                    _vm.userNames = $$v
                                  },
                                  expression: "userNames"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        {
                          attrs: {
                            span: 22,
                            offset: 1
                          }
                        },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "简介：",
                                prop: "leaf"
                              }
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  type: "textarea",
                                  resize: "none",
                                  rows: 8
                                },
                                model: {
                                  value: _vm.formValidate.remark,
                                  callback: function callback($$v) {
                                    _vm.$set(_vm.formValidate, "remark", $$v)
                                  },
                                  expression: "formValidate.remark"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    {
                      attrs: {
                        span: 10,
                        offset: 10
                      }
                    },
                    [
                      _c("load-btn", {
                        attrs: {
                          "btn-data": _vm.loadBtn
                        },
                        on: {
                          listenSubEvent: _vm.listenSubEvent
                        }
                      }),
                      _c(
                        "el-button",
                        {
                          on: {
                            click: _vm.cancel
                          }
                        },
                        [_vm._v("\n          取消\n        ")]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "Modal",
                {
                  attrs: {
                    width: "1000",
                    "class-name": "vertical-center-modal"
                  },
                  model: {
                    value: _vm.selectUserModal,
                    callback: function callback($$v) {
                      _vm.selectUserModal = $$v
                    },
                    expression: "selectUserModal"
                  }
                },
                [
                  _c("modal-header", {
                    attrs: {
                      slot: "header",
                      content: _vm.selectUserId
                    },
                    slot: "header"
                  }),
                  _vm.selectUserModal
                    ? _c("select-user", {
                        attrs: {
                          "init-user": _vm.users
                        },
                        on: {
                          cancel: _vm.closeUserModal,
                          setUsers: _vm.setUsers
                        }
                      })
                    : _vm._e(),
                  _c("div", {
                    attrs: {
                      slot: "footer"
                    },
                    slot: "footer"
                  })
                ],
                1
              ),
              _c(
                "Modal",
                {
                  attrs: {
                    width: "1000",
                    "class-name": "vertical-center-modal"
                  },
                  model: {
                    value: _vm.selectDepModal,
                    callback: function callback($$v) {
                      _vm.selectDepModal = $$v
                    },
                    expression: "selectDepModal"
                  }
                },
                [
                  _c("modal-header", {
                    attrs: {
                      slot: "header",
                      content: _vm.selectDepId
                    },
                    slot: "header"
                  }),
                  _vm.selectDepModal
                    ? _c("select-dep", {
                        attrs: {
                          "operaility-data": _vm.selectDepArr
                        },
                        on: {
                          cancel: function cancel($event) {
                            return _vm.closeModal("selectDep")
                          },
                          setDep: _vm.setDep
                        }
                      })
                    : _vm._e(),
                  _c("div", {
                    attrs: {
                      slot: "footer"
                    },
                    slot: "footer"
                  })
                ],
                1
              )
            ],
            1
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }