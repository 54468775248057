const yonghufabu = { // 用户
  path: 'yonghufabu',
  name: 'yonghufabu',
  meta: {
    requireAuth: false // 添加该字段，表示进入这个路由是需要登录的
  },
  component: resolve=> {
    require.ensure([], () => {
      resolve(require('_c/zixun/yonghufabu/yonghufabu_list'))
    }, 'yonghufabu')
  }
}
const zixunfabu = { // 注册用户
  path: 'zixunfabu',
  name: 'zixunfabu',
  meta: {
    requireAuth: false // 添加该字段，表示进入这个路由是需要登录的
  },
  component: resolve=> {
    require.ensure([], () => {
      resolve(require('_c/zixun/zixunfabu/zixunfabu_list'))
    }, 'zixunfabu')
  }
}
const zixuntongji = { // 注册用户
  path: 'zixuntongji',
  name: 'zixuntongji',
  meta: {
    requireAuth: false // 添加该字段，表示进入这个路由是需要登录的
  },
  component: resolve=> {
    require.ensure([], () => {
      resolve(require('_c/zixun/zixuntongji/zixuntongji_list'))
    }, 'zixuntongji')
  }
}
const zixunshenhe = { // 注册用户
  path: 'zixunshenhe',
  name: 'zixunshenhe',
  meta: {
    requireAuth: false // 添加该字段，表示进入这个路由是需要登录的
  },
  component: resolve=> {
    require.ensure([], () => {
      resolve(require('_c/zixun/zixunshenhe/zixunshenhe_list'))
    }, 'zixunshenhe')
  }
}
const zixunjubao = { // 注册用户
  path: 'zixunjubao',
  name: 'zixunjubao',
  meta: {
    requireAuth: false // 添加该字段，表示进入这个路由是需要登录的
  },
  component: resolve=> {
    require.ensure([], () => {
      resolve(require('_c/zixun/zixunjubao/zixunjubao_list'))
    }, 'zixunjubao')
  }
}
export {
  yonghufabu,
  zixunfabu,
  zixuntongji,
  zixunshenhe,
  zixunjubao
}

