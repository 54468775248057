var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    [
      _c(
        "div",
        {
          staticClass: "remove"
        },
        [_vm._v("\n    " + _vm._s(_vm.text || _vm.operateMess.title) + "\n  ")]
      ),
      _c(
        "el-row",
        [
          _c(
            "el-col",
            {
              attrs: {
                span: 22,
                align: "right"
              }
            },
            [
              _c("load-btn", {
                staticClass: "blueBtn",
                attrs: {
                  "btn-data": _vm.loadBtn
                },
                on: {
                  operateCallback: _vm.operateCallback
                }
              }),
              _c(
                "el-button",
                {
                  staticClass: "but-col cancelBtn",
                  attrs: {
                    type: "danger"
                  },
                  on: {
                    click: _vm.cancel
                  }
                },
                [_vm._v("\n        取消\n      ")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }