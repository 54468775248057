var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    [
      _c(
        "el-form",
        {
          ref: "formValidate",
          staticClass: "demo-form-inline",
          attrs: {
            model: _vm.formValidate,
            type: _vm.type,
            rules: _vm.rules,
            "label-width": "110px"
          }
        },
        [
          _c(
            "el-row",
            [
              _c(
                "el-col",
                {
                  attrs: {
                    span: 10,
                    offset: 6
                  }
                },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "部门名称：",
                        prop: "remark"
                      }
                    },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            filterable: "",
                            placeholder: "请选择"
                          },
                          model: {
                            value: _vm.formValidate.remark,
                            callback: function callback($$v) {
                              _vm.$set(_vm.formValidate, "remark", $$v)
                            },
                            expression: "formValidate.remark"
                          }
                        },
                        _vm._l(_vm.schoolOptions, function(item, index) {
                          return _c("el-option", {
                            key: index,
                            attrs: {
                              label: item.name,
                              value: item.id + ""
                            }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-row",
        [
          _c(
            "el-col",
            {
              attrs: {
                span: 10,
                offset: 10
              }
            },
            [
              _c("load-btn", {
                attrs: {
                  "btn-data": _vm.loadBtn
                },
                on: {
                  listenSubEvent: _vm.listenSubEvent
                }
              }),
              _c(
                "el-button",
                {
                  on: {
                    click: _vm.cancel
                  }
                },
                [_vm._v("\n        取消\n      ")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }