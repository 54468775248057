var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "el-button",
    {
      staticClass: "sureBtn submitBtn",
      attrs: {
        size: _vm.btnData.size,
        type: _vm.type,
        loading: _vm.loading
      },
      on: {
        click: _vm.submit
      }
    },
    [
      !_vm.loading
        ? _c("span", [_vm._v(_vm._s(_vm.btnData.title))])
        : _c("span", [_vm._v("加载中")])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }