const jichutiku = { // 试题库
  path: 'jichutiku',
  name: 'jichutiku',
  meta: {
    requireAuth: false // 添加该字段，表示进入这个路由是需要登录的
  },
  component: resolve=> {
    require.ensure([], () => {
      resolve(require('_c/tiku/jichutiku/jichutiku_list'))
    }, 'jichutiku')
  }
}
const jigoutiku = { // 机构题库
  path: 'jigoutiku',
  name: 'jigoutiku',
  meta: {
    requireAuth: false // 添加该字段，表示进入这个路由是需要登录的
  },
  component: resolve=> {
    require.ensure([], () => {
      resolve(require('_c/tiku/jigoutiku/jigoutiku_list'))
    }, 'jigoutiku')
  }
}
const tikulianxi = { // 机构题库
  path: 'tikulianxi',
  name: 'tikulianxi',
  meta: {
    requireAuth: false // 添加该字段，表示进入这个路由是需要登录的
  },
  component: resolve=> {
    require.ensure([], () => {
      resolve(require('_c/tiku/tikulianxi/tikulianxi_list'))
    }, 'tikulianxi')
  }
}
const xitongtixing = { // 机构题库
  path: 'xitongtixing',
  name: 'xitongtixing',
  meta: {
    requireAuth: false // 添加该字段，表示进入这个路由是需要登录的
  },
  component: resolve=> {
    require.ensure([], () => {
      resolve(require('_c/tiku/xitongtixing/xitongtixing_list'))
    }, 'xitongtixing')
  }
}
const zhenglitiku = { // 机构题库
  path: 'zhenglitiku',
  name: 'zhenglitiku',
  meta: {
    requireAuth: false // 添加该字段，表示进入这个路由是需要登录的
  },
  component: resolve=> {
    require.ensure([], () => {
      resolve(require('_c/tiku/zhenglitiku/zhenglitiku_list'))
    }, 'zhenglitiku')
  }
}
const xueqingceshi = { // 机构题库
  path: 'xueqingceshi',
  name: 'xueqingceshi',
  meta: {
    requireAuth: false // 添加该字段，表示进入这个路由是需要登录的
  },
  component: resolve=> {
    require.ensure([], () => {
      resolve(require('_c/tiku/xueqingceshi/index'))
    }, 'xueqingceshi')
  }
}
export {
  jichutiku,
  jigoutiku,
  tikulianxi,
  xitongtixing,
  zhenglitiku,
  xueqingceshi
}
