const shujushouye = { // 试题库
  path: 'shujushouye',
  name: 'shujushouye',
  meta: {
    requireAuth: false // 添加该字段，表示进入这个路由是需要登录的
  },
  component: resolve=> {
    require.ensure([], () => {
      resolve(require('_c/jinyitu/shujushouye/shujushouye_list'))
    }, 'shujushouye')
  }
}
const menu = { // 试题库
  path: 'menu',
  name: 'menu',
  meta: {
    requireAuth: false // 添加该字段，表示进入这个路由是需要登录的
  },
  component: resolve=> {
    require.ensure([], () => {
      resolve(require('_c/jinyitu/menu/menu_list'))
    }, 'menu')
  }
}
const huodong = { // 活动
  path: 'huodong',
  name: 'huodong',
  meta: {
    requireAuth: false // 添加该字段，表示进入这个路由是需要登录的
  },
  component: resolve=> {
    require.ensure([], () => {
      resolve(require('_c/jinyitu/huodong/management'))
    }, 'huodong')
  }
}
const xingyundazhuanpan = { // 咨询
  path: 'xingyundazhuanpan',
  name: 'xingyundazhuanpan',
  meta: {
    requireAuth: false // 添加该字段，表示进入这个路由是需要登录的
  },
  component: resolve=> {
    require.ensure([], () => {
      resolve(require('_c/jinyitu/xingyundazhuanpan/turntable'))
    }, 'xingyundazhuanpan')
  }
}

const luckyDraw = { // 咨询
  path: 'luckyDraw',
  name: 'luckyDraw',
  meta: {
    requireAuth: false // 添加该字段，表示进入这个路由是需要登录的
  },
  component: resolve=> {
    require.ensure([], () => {
      resolve(require('_c/jinyitu/xingyundazhuanpan/luckyDraw'))
    }, 'luckyDraw')
  }
}
const aboutus = { // 咨询
  path: 'aboutus',
  name: 'aboutus',
  meta: {
    requireAuth: false // 添加该字段，表示进入这个路由是需要登录的
  },
  component: resolve=> {
    require.ensure([], () => {
      resolve(require('_c/jinyitu/aboutus/aboutus_list'))
    }, 'aboutus')
  }
}
const appversionmanage = { // app版本管理
  path: 'appversionmanage',
  name: 'appversionmanage',
  meta: {
    requireAuth: false // 添加该字段，表示进入这个路由是需要登录的
  },
  component: resolve=> {
    require.ensure([], () => {
      resolve(require('_c/jinyitu/appversionmanage/appversionmanage_list'))
    }, 'appversionmanage')
  }
}
const noticeInfo = { // 消息推送
  path: 'noticeInfo',
  name: 'noticeInfo',
  meta: {
    requireAuth: false // 添加该字段，表示进入这个路由是需要登录的
  },
  component: resolve=> {
    require.ensure([], () => {
      resolve(require('_c/jinyitu/noticeInfo/index'))
    }, 'noticeInfo')
  }
}
const yindaotu = { // 引导图
  path: 'yindaotu',
  name: 'yindaotu',
  meta: {
    requireAuth: false // 添加该字段，表示进入这个路由是需要登录的
  },
  component: resolve=> {
    require.ensure([], () => {
      resolve(require('_c/jinyitu/yindaotu/yindaotu_list'))
    }, 'yindaotu')
  }
}
const banner = { // 首页banner
  path: 'banner',
  name: 'banner',
  meta: {
    requireAuth: false // 添加该字段，表示进入这个路由是需要登录的
  },
  component: resolve=> {
    require.ensure([], () => {
      resolve(require('_c/jinyitu/banner/banner_list'))
    }, 'banner')
  }
}
const shouyetuijian = { // 首页推荐
  path: 'shouyetuijian',
  name: 'shouyetuijian',
  meta: {
    requireAuth: false // 添加该字段，表示进入这个路由是需要登录的
  },
  component: resolve=> {
    require.ensure([], () => {
      resolve(require('_c/jinyitu/shouyetuijian/shouyetuijian_list'))
    }, 'shouyetuijian')
  }
}

const redeemCode = { // 产品兑换码
  path: 'redeemCode',
  name: 'redeemCode',
  meta: {
    requireAuth: false // 添加该字段，表示进入这个路由是需要登录的
  },
  component: resolve=> {
    require.ensure([], () => {
      resolve(require('_c/jinyitu/redeemCode/redeemCode_list'))
    }, 'redeemCode')
  }
}
const dingdanguanli = { // 订单管理
  path: 'dingdanguanli',
  name: 'dingdanguanli',
  meta: {
    requireAuth: false // 添加该字段，表示进入这个路由是需要登录的
  },
  component: resolve=> {
    require.ensure([], () => {
      resolve(require('_c/jinyitu/orderManagement/orderList'))
    }, 'dingdanguanli')
  }
}

const caiwushenpi = { // 财务审批
  path: 'caiwushenpi',
  name: 'caiwushenpi',
  meta: {
    requireAuth: false // 添加该字段，表示进入这个路由是需要登录的
  },
  component: resolve=> {
    require.ensure([], () => {
      resolve(require('_c/jinyitu/financialApproval/index'))
    }, 'caiwushenpi')
  }
}

export {
  shujushouye,
  menu,
  huodong,
  xingyundazhuanpan,
  luckyDraw,
  aboutus,
  appversionmanage,
  yindaotu,
  banner,
  shouyetuijian,
  redeemCode,
  dingdanguanli,
  caiwushenpi,
  noticeInfo

}
