var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "el-select",
    {
      attrs: {
        multiple: _vm.sltOptions.multiple,
        filterable: _vm.sltOptions.filterable,
        "allow-create": _vm.sltOptions.allowCreate,
        placeholder: "选择或输入匹配搜索"
      },
      on: {
        change: _vm.change
      },
      model: {
        value: _vm.value,
        callback: function callback($$v) {
          _vm.value = $$v
        },
        expression: "value"
      }
    },
    _vm._l(_vm.optionData, function(item) {
      return _c("el-option", {
        key: item.name,
        attrs: {
          label: item.name,
          value: item.name
        }
      })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }