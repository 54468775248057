var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    [
      _c(
        "el-form",
        {
          ref: "formValidate",
          staticClass: "demo-form-inline",
          attrs: {
            "label-width": "110px"
          }
        },
        [
          _c(
            "el-row",
            [
              _c(
                "el-col",
                {
                  attrs: {
                    span: 10,
                    offset: 2
                  }
                },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "节点名称：",
                        prop: "treeName"
                      }
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          placeholder: "请输入"
                        },
                        model: {
                          value: _vm.formValidate.treeName,
                          callback: function callback($$v) {
                            _vm.$set(_vm.formValidate, "treeName", $$v)
                          },
                          expression: "formValidate.treeName"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                {
                  attrs: {
                    span: 16,
                    offset: 2
                  }
                },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "节点简介：",
                        prop: "remark"
                      }
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          type: "textarea",
                          resize: "none",
                          rows: 8
                        },
                        model: {
                          value: _vm.formValidate.remark,
                          callback: function callback($$v) {
                            _vm.$set(_vm.formValidate, "remark", $$v)
                          },
                          expression: "formValidate.remark"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-row",
        [
          _c(
            "el-col",
            {
              attrs: {
                span: 10,
                offset: 10
              }
            },
            [
              _c("load-btn", {
                attrs: {
                  btnData: _vm.loadBtn
                },
                on: {
                  listenSubEvent: _vm.listenSubEvent
                }
              }),
              _c(
                "el-button",
                {
                  on: {
                    click: _vm.cancel
                  }
                },
                [_vm._v("取消")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }