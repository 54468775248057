/*
 * @Author: luo
 * @Date: 2023-09-21 10:24
 * @LastEditors: kavinluo
 * @LastEditTime: 2023-09-21 14:41
 * @Description: 日统计
 */
const dayLog = { // 日统计
  path: 'dayLog',
  name: 'dayLog',
  meta: {
    requireAuth: false // 添加该字段，表示进入这个路由是需要登录的
  },
  component: resolve=> {
    require.ensure([], () => {
      resolve(require('_c/sytLog/dayLog/dayLogList.vue'))
    }, 'dayLog')
  }
}
const functionLog = { // 日统计
  path: 'functionLog',
  name: 'functionLog',
  meta: {
    requireAuth: false // 添加该字段，表示进入这个路由是需要登录的
  },
  component: resolve=> {
    require.ensure([], () => {
      resolve(require('_c/sytLog/functionLog.vue'))
    }, 'functionLog')
  }
}
export {
  dayLog,
  functionLog
}
