var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    [
      _vm.title
        ? _c(
            "div",
            {
              staticClass: "remove"
            },
            [_vm._v("\n    " + _vm._s(_vm.title) + "\n  ")]
          )
        : _c(
            "div",
            {
              staticClass: "remove"
            },
            [_vm._v("\n    确定要删除吗？\n  ")]
          ),
      _c(
        "el-row",
        [
          _c(
            "el-col",
            {
              attrs: {
                span: 10,
                offset: 14
              }
            },
            [
              _c("load-btn", {
                attrs: {
                  "btn-data": _vm.loadBtn
                },
                on: {
                  handleRemove: _vm.handleRemove
                }
              }),
              _c(
                "el-button",
                {
                  staticClass: "but-col cancelBtn",
                  on: {
                    click: function click($event) {
                      return _vm.cancel("remove")
                    }
                  }
                },
                [_vm._v("\n        取消\n      ")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }