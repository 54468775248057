var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    {
      staticClass: "vue-xlsx-container"
    },
    [
      _c(
        "el-button",
        {
          staticClass: "xlsx-button blueBtn",
          attrs: {
            size: "medium",
            type: "primary"
          },
          on: {
            click: _vm.handleUploadBtnClick
          }
        },
        [
          _vm._v("\n    上传\n    "),
          _c("i", {
            staticClass: "el-icon-upload el-icon--right"
          })
        ]
      ),
      _c("input", {
        staticClass: "c-hide hidden",
        attrs: {
          id: "upload-input",
          type: "file",
          accept: _vm.accept
        },
        on: {
          change: _vm.handkeFileChange
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }