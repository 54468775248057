
const chanpinbaoguanlichild = {
  path: 'chanpinbaoguanlichild',
  name: 'chanpinbaoguanlichild',
  meta: {
    requireAuth: false // 添加该字段，表示进入这个路由是需要登录的
  },
  component: resolve=> {
    require.ensure([], () => {
      resolve(require('_c/chanpinbaoguan/chanpinbaoguan/index.vue'))
    }, 'chanpinbaoguanlichild')
  }
}

export {
  chanpinbaoguanlichild

}

