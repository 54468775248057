const companyProfile = { // 咨询
  path: 'companyProfile',
  name: 'companyProfile',
  meta: {
    requireAuth: false // 添加该字段，表示进入这个路由是需要登录的
  },
  component: resolve=> {
    require.ensure([], () => {
      resolve(require('_c/goldenMedical/companyProfile'))
    }, 'companyProfile')
  }
}
const aboutWe = { // 咨询
  path: 'aboutWe',
  name: 'aboutWe',
  meta: {
    requireAuth: false // 添加该字段，表示进入这个路由是需要登录的
  },
  component: resolve=> {
    require.ensure([], () => {
      resolve(require('_c/goldenMedical/aboutWe'))
    }, 'aboutWe')
  }
}
const service = { // 咨询
  path: 'service',
  name: 'service',
  meta: {
    requireAuth: false // 添加该字段，表示进入这个路由是需要登录的
  },
  component: resolve=> {
    require.ensure([], () => {
      resolve(require('_c/goldenMedical/service'))
    }, 'service')
  }
}
const goldenMedical = { // 咨询
  path: 'goldenMedical',
  name: 'goldenMedical',
  meta: {
    requireAuth: false // 添加该字段，表示进入这个路由是需要登录的
  },
  component: resolve=> {
    require.ensure([], () => {
      resolve(require('_c/goldenMedical/goldenMedical'))
    }, 'goldenMedical')
  }
}
const userProtocol = { // 咨询
  path: 'userProtocol',
  name: 'userProtocol',
  meta: {
    requireAuth: false // 添加该字段，表示进入这个路由是需要登录的
  },
  component: resolve=> {
    require.ensure([], () => {
      resolve(require('_c/goldenMedical/userProtocol'))
    }, 'userProtocol')
  }
}
export {
  companyProfile,
  aboutWe,
  service,
  goldenMedical,
  userProtocol
}
