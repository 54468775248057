var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    [
      _c(
        "div",
        {
          staticClass: "remove"
        },
        [_vm._v(_vm._s(_vm.title))]
      ),
      _c(
        "el-row",
        [
          _c(
            "el-col",
            {
              attrs: {
                span: 10,
                offset: 14
              }
            },
            [
              _c(
                "a",
                {
                  attrs: {
                    href: _vm.url
                  }
                },
                [
                  _c(
                    "el-button",
                    {
                      staticClass: "sureBtn",
                      on: {
                        click: _vm.cancel
                      }
                    },
                    [_vm._v("确定")]
                  )
                ],
                1
              ),
              _c(
                "el-button",
                {
                  staticClass: "but-col cancelBtn",
                  on: {
                    click: _vm.cancel
                  }
                },
                [_vm._v("取消")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }