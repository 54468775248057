/*
 * dictionary 所有模块引用的前台业务字典
 * 作者:zyc
 * 日期:2017-05-13
 *
 * */

// 本地字典
export default {
  // 修改记录审批状态
  configColumn: {
    'course': '课程'
  },
  // 审批状态字体颜色
  configValue: {
    'live_button': '直播按钮',
    'home': '首页配置'
  }
}
