var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h

    return _c(
      "div",
      {
        staticClass: "loading"
      },
      [
        _c("p", [
          _c("i", {
            staticClass: "el-icon-loading"
          }),
          _vm._v(" 加载中...")
        ])
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }